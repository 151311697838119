import { BlobServiceClient } from '@azure/storage-blob';

const blobServiceClient = new BlobServiceClient(
  'https://fjmotorsstorageaccount.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2094-10-20T16:41:31Z&st=2024-10-10T08:41:31Z&spr=https,http&sig=y%2FiXlB3buqvfE21MiCoM3TANU1xeVpRPZUt54ruFdVQ%3D'
);

// this comes from the sas url above. everyihing after the '?'
export const SAS_TOKEN = 'sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2094-10-20T16:41:31Z&st=2024-10-10T08:41:31Z&spr=https,http&sig=y%2FiXlB3buqvfE21MiCoM3TANU1xeVpRPZUt54ruFdVQ%3D';

const containerName = 'vehicle';
export const containerClient = blobServiceClient.getContainerClient(containerName);
