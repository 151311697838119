<template>
  <div>
    <NavBar />

    <div class="page-container">
      <main class="content">
        <div v-if="!isLoading" class="container">
          <div class="vehicle-details" v-if="!isMobile()">
            <h2 class="vehicle-title"> {{ vehicle?.title }}</h2>
            <router-link v-if="isAdmin" :to="'/editVehicle/' + vehicle?.id" class="edit-button">Edit</router-link>
            <button class="btn btn-whatsapp" v-if="!isAdmin" @click="onEnquire(vehicle?.id)">
                <i class="fab fa-whatsapp"></i> Enquire
            </button>
          </div>
          <div class="vehicle-details" v-if="isMobile()">
            <h2 class="vehicle-title-mobile"> {{ vehicle?.title }}</h2>
          </div>
          <div class="row">
            <div class="col-md-8" :class="{ 'my-col-md-8': isMobile() }">
              <div class="position-relative">
                <div class="image-container">
                  <img :src="vehicle?.images[imageIndex]" alt="Vehicle Image" class="my-image" />
                </div>
                <div class="position-absolute start-0 top-50 translate-middle-y left-arrow-container">
                  <i class="fas fa-chevron-left fa-2x left-arrow" @click="changeImage(-1)"></i>
                </div>
                <div class="position-absolute top-50 end-0 translate-middle-y right-arrow-container">
                  <i class="fas fa-chevron-right fa-2x right-arrow" @click="changeImage(1)"></i>
                </div>
              </div>
              <div class="small-image-wrapper">
                <div class="small-image-container" style="overflow-x: auto; overflow-y: hidden">
                  <div class="image-scroll">
                    <div v-for="(image, index) in vehicle?.images" :key="index" :class="{ '': index === imageIndex }">
                      <img :src="image" alt="Product Image" :class="activeImageClass(index)"
                        @click="selectImage(index)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="hover-container">
                <div class="card-header">
                  <h2 class="vheader">Vehicle Details:</h2>
                  <div class="share-button">
                    <i class="fa fa-share-alt"></i>
                    <div class="share-dropdown" :class="isMobile() ? 'share-dropdown-mobile' : ''">
                      <a @click="shareOnWhatsapp"> <i class="fab fa-whatsapp" style="color:green"></i>
                        WhatsApp </a>
                      <a @click="shareOnFB"> <i class="fab fa-facebook" style="color:blue"></i>
                        Facebook </a>
                    </div>
                  </div>
                </div>
                <div class="card p-3 info-card">
                  <div v-for="(item, index) in vehicleInfo" :key="index" class="row info-row pb-2 mb-2" :class="{ 'info-card-even-bg': isEvenIndex(index) }">
                    <div class="col-6">{{ item.key }}</div>
                    <div class="col-6">{{ item.value }}</div>
                  </div>
                  <div class="text-center mt-4">
                    <h2 class="vehicle-price"><b>{{ price }}</b></h2>
                  </div>
                </div>
              </div>
              <div v-if="isMobile()" style="margin-top:20px" class="d-flex realtive justify-content-center">
                <router-link v-if="isAdmin" :to="'/editVehicle/' + vehicle?.id" class="edit-button">Edit</router-link>
                <button class="btn btn-whatsapp" v-if="!isAdmin" @click="onEnquire(vehicle?.id)">
                    <i class="fab fa-whatsapp"></i> Enquire
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-center loading-icon">
          <!-- Use v-if to conditionally display the loading icon -->
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </main>
      <FooterComponent />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NavBar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import { isMobileDevice } from '@/common/commonFunctions';
import { fetchSingleVehicle, Vehicle } from '@/api/vehicle';
import { camelCaseToSpacedString, formatNumberWithSpaces, redirectToWhatsApp } from '@/util/common';
import { SAS_TOKEN } from '@/api/azureBlob';

export default defineComponent({
  name: "VehicleView",
  components: {
    NavBar,
    FooterComponent: Footer,
  },
  data() {
    return {
      isHovering: false,
      imageIndex: 0,
      // images: [
      //     'https://img.autotrader.co.za/26099264/Crop800x600',
      //     'https://img.autotrader.co.za/26099262/Crop800x600',
      //     'https://img.autotrader.co.za/26099263/Crop800x600',
      //     'https://img.autotrader.co.za/26099264/Crop800x600',
      //     'https://img.autotrader.co.za/26099262/Crop800x600',
      //     'https://img.autotrader.co.za/26099263/Crop800x600',
      //     'https://img.autotrader.co.za/26099264/Crop800x600',
      //     'https://img.autotrader.co.za/26099262/Crop800x600',
      //     'https://img.autotrader.co.za/26099263/Crop800x600',
      //     'https://img.autotrader.co.za/26099264/Crop800x600',
      // ],
      vehicleInfo: [] as { key: string; value: string }[],
      vehicle: undefined as Vehicle | undefined,
      isLoading: true,
      price: '',
      isAdmin: false,
    };
  },
  methods: {
    changeImage(step: any) {
      this.imageIndex = (this.imageIndex + step + this.vehicle?.images.length) % (this.vehicle?.images as string[]).length;
    },
    selectImage(index: any) {
      this.imageIndex = index;
    },
    activeImageClass(index: any) {
      if (index === this.imageIndex) {
        return "img-fluid small-image small-image-selected";
      } else {
        return "img-fluid small-image";
      }
    },
    isMobile() {
      return isMobileDevice();
    },
    onEnquire(id?: string) {
      // const url = window.location.href;
      redirectToWhatsApp(id);
    },
    shareOnFB() {
      const url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href);
      window.open(url, '_blank');
    },
    shareOnWhatsapp() {
      const url = 'whatsapp://send?text=' + encodeURIComponent(`https://fjmotor.co.za?id=${this.$route.params.id}`);
      window.open(url, '_blank');
    },
    isEvenIndex(index: number): boolean {
      return index % 2 === 1;
    },

    updateVehicleSasUrl() {
      if (this.vehicle) {
      for (const index in this.vehicle.images) {
          const base = this.vehicle.images[index].split('?')[0];
          this.vehicle.images[index] = `${base}?${SAS_TOKEN}`;
        }
      }
    }
  },

  async mounted() {
    const user = this.$store.getters['user/getCurrentUser'];
    if (user) {
      this.isAdmin = true;
    }

    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      this.isAdmin = true; // Extract the isAdmin property
    }

    const id = this.$route.params.id;
    this.vehicle = await fetchSingleVehicle({ id });
    this.updateVehicleSasUrl();
    this.isLoading = false;
    if (this.vehicle) {
      for (let [key, value] of Object.entries(this.vehicle)) {
        let stringValue = '';
        if (key !== 'images' && key !== 'id' && key !== 'createdAt' && key !== 'title' && key !== 'priority' && key !== 'category') {
          if (typeof value === 'number') {
            if (value === 0) {
              continue;
            }
            if (key === 'price') {
              this.price = `R${formatNumberWithSpaces(value)}`;
              continue;
            } if (key === 'fuelTankCapacity') {
              stringValue = `${value}L`;
            } else if (key === 'mileage') {
              stringValue = `${formatNumberWithSpaces(value)} km`;
            } else {
              stringValue = formatNumberWithSpaces(value);
            }
          } else if (typeof value === 'string') {
            if (value === '') {
              continue;
            }
            if (key === 'dateOfLicensing') {
              key = 'yearModel';
            }
            stringValue = value;
          }

          this.vehicleInfo.push({ key: camelCaseToSpacedString(key), value: stringValue });
        }

      }
    } else {
      this.$router.replace("/error");
      console.error('Vehicle not found')
    }
  },
  beforeDestroy() {
    this.isAdmin = false;
  },
});
</script>

<style scoped lang="scss">
.my-col-md-8 {
  padding-right: 0px !important;
  padding-left: 0px !important;
  position: relative;
  justify-content: center;
  align-content: center;
}

.col-md-8 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 80px;
}

.content {
  flex: 1;
}

.product-image {
  // width: 100%;
  // height: auto;
  // max-height: 30em;
  height: 30em;
  width: 43em;
}

/*.small-image {
    width: 10em;
    height: 7em;
    cursor: pointer;
    margin-right: 1em;
    margin-left: 1em;
}*/

@media (max-width: 767px) {
  .product-image {
    height: auto;
    max-width: 100%;
  }

  .position-absolute {
    position: static;
    margin: 0.5rem 0;
    text-align: center;
  }
}

.vehicle-title {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 2.3em;
  color: rgb(54, 54, 54);
  text-align: left;
  margin-bottom: 20px;
  transition: 0.2s ease;
  width: fit-content;
  flex-grow: 1;
}

.vehicle-title-mobile {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size:  1.3em;
  color: rgb(54, 54, 54);
  text-align: left;
  margin-bottom: 20px;
  transition: 0.2s ease;
  width: fit-content;
  flex-grow: 1;
}

.vehicle-details {
  display: flex;
  align-items: center;
}

.enquire-button {
  color: #007bff;
  border: 1px solid #007bff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: 0.2s ease;
  width: 6em;
}

.enquire-button:hover {
  color: #fff;
  background-color: #007bff;
}

.edit-button {
  color: #007bff;
  border: 1px solid #007bff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: 0.2s ease;
  width: 6em;
  margin-right: 10px;
}

.edit-button:hover {
  color: #fff;
  background-color: #007bff;
}

.vehicle-title:hover {
  color: rgb(29, 29, 29);
  transform: translateY(-3px);
}

.favourite-icon {
  margin-right: 6em;
  margin-top: 1em;
}

.vehicle-price {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 2em;
  color: rgb(43, 115, 182);
  transition: 0.2s ease;
}

.vehicle-price:hover {
  transform: translateY(-5px);
  font-size: 2.5em;
}

/*.left-arrow {
    color: rgba(255, 255, 255, 0.7);
    transition: 0.1s ease;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2%;
    font-size: 34px;
}

.right-arrow {
    color: rgba(255, 255, 255, 0.7);
    transition: 0.1s ease;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2%;
    font-size: 34px;
}*/

.img-fluid {
  padding-left: 0px !important;
  margin-left: 0px !important;
}

.my-image {
  width: 100%;
  border-radius: 10px;
}

.image-container {
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: 0.1s ease;
}

.image-container:hover {
  border: 2px solid #00348d;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.left-arrow-container,
.right-arrow-container {
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.7);
  transition: 0.1s ease;
  position: absolute;
  font-size: 24px !important;
}

.left-arrow-container {
  left: 2% !important;
}

.right-arrow-container {
  right: 2% !important;
}

.left-arrow:hover {
  color: rgba(255, 255, 255, 0.9);
}

.right-arrow:hover {
  color: rgba(255, 255, 255, 0.9);
}

.small-image-container {
  width: 57em;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-color: #ccc transparent;
  scrollbar-width: 4px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1em;
}

.image-scroll {
  display: flex;
}

.small-image {
  min-width: 7em !important;
  height: 5em !important;
  cursor: pointer;
  margin-right: 1em;
  margin-left: 1em;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  transition: 0.2s ease;
}

.small-image:hover {
  transform: scale(1.01);
  margin-right: 1.1em;
  margin-left: 1.1em;
  border: 2px solid #004696;
}

.small-image-selected{
transform: scale(1.01);
  margin-right: 1.1em;
  margin-left: 1.1em;
  border: 2px solid #004696;
}

.small-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1em;
}

.card-header {
  border: 1px solid #d3d3d3;
  border-radius: 0px;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s ease;
  background-color: #063E88;
}

.card-header:hover {
  /*border-left: 1px solid #00348d;
    border-right: 1px solid #00348d;
    border-top: 1px solid #00348d;*/
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.vheader {
  margin: 0;
  font-size: 1.7em;
  color: #fff;
  border: 1px solid #063E88;

}

.share-button {
  position: relative;
  cursor: pointer;
  padding-left: 20px;
  color: #fff
}

.share-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #000
}

.share-dropdown-mobile {
  display: none;
  position: absolute;
  top: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: 15px;
  margin-right: 15px !important;
}

.share-dropdown a:nth-child(odd){
  display: flex;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  color: #000;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.share-dropdown a:nth-child(even){
  display: flex;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  color: #000;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.share-dropdown a:hover {
  background-color: #f0f0f0;
}

.share-button:hover  .share-dropdown {
  display: block;
}

/* Icons for WhatsApp, Instagram, and Facebook (You may need to replace these URLs with actual icons) */
.icon-whatsapp::before {
  content: "\f232";
  /* WhatsApp icon */
}

.icon-instagram::before {
  content: "\f16d";
  /* Instagram icon */
}

.icon-facebook::before {
  content: "\f09a";
  /* Facebook icon */
}

.fab {
  margin-right: 5px;
}

.info-card {
  border: 1px solid #d3d3d3;
  border-top: 0px;
  transition: 0.2s ease;
  border-radius: 0px;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.info-card-even-bg {
  background-color: #f1f1f1;
}

.info-card:hover {
  /*border-left: 1px solid #00348d;
    border-right: 1px solid #00348d;
    border-bottom: 1px solid #00348d;*/
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.hover-container:hover .card-header,
.hover-container:hover .info-card {
  /*border-left: 1px solid #00348d;
  border-right: 1px solid #00348d;
  border-top: 1px solid #00348d;*/
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.info-row {
  transition: transform 0.3s ease;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid #d3d3d3;
  padding-top: 5px;
  border-radius: 10px;
}

.info-row:hover {
  background-color: #f4fcff;
  font-weight: bold;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  //transform: translateY(-3px);
}

::-webkit-scrollbar {
  height: 6px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.loading-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.btn-whatsapp {
  background: transparent;
  border: 1px solid #25d366;
  border-radius: 5px;
  color: #25d366;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s;
  padding: 10px 20px;

  i {
    margin-right: 5px;
  }

  &:hover {
    background-color: #25d366;
    color: #fff;
  }
}
</style>