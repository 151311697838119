import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import VueGtm from 'vue-gtm';

// attampt 1 at setting up google tag
// Google Analytics setup
// if (process.env.NODE_ENV === 'production') {
//   // Load Google Analytics script
//   const script = document.createElement('script');
//   script.async = true;
//   script.src = `https://www.googletagmanager.com/gtag/js?id=G-7TEYDV4QSN`; 
//   document.head.appendChild(script);

//   // Initialize gtag function
//   /* eslint-disable */
//   //@ts-ignore
//   window.dataLayer = window.dataLayer || [];
//   //@ts-ignore
//   function gtag(...args: any[]) {
//     //@ts-ignore
//     window.dataLayer.push(args);
//   }

//   gtag('js', new Date());
//   gtag('config', 'G-7TEYDV4QSN'); 
// }

// // Router hook to track page views
// router.afterEach((to) => {
//   if (process.env.NODE_ENV === 'production') {
//     //@ts-ignore
//     gtag('event', 'page_view', {
//       page_path: to.fullPath,
//     });
//   }
// });

// attampt 2 at setting up google tag
// Vue.use(VueGtm, {
//   id: 'GTM-NNDVWH9M', // GTM ID
//   enabled: true, // Set to false if you want to disable it temporarily
//   debug: false,   // Set to true to enable debug mode
//   vueRouter: router, // Pass your router instance here if you want page tracking
// });

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


// <!-- Google tag (gtag.js) -->
// <script async src="https://www.googletagmanager.com/gtag/js?id=G-7TEYDV4QSN"></script>
// <script>
//   window.dataLayer = window.dataLayer || [];
//   function gtag(){dataLayer.push(arguments);}
//   gtag('js', new Date());

//   gtag('config', 'G-7TEYDV4QSN');
// </script>